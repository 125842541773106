import React, { useState } from 'react';
const Header = () => {
    return (
        <>
            <header className='header py-3'>
                <h3 className='fw-bolder my-2'><img src={require('../../assets/images/logo/logo_blue.svg').default}/></h3>
            </header>
        </>
    )
}
export default Header