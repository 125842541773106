import React, { useEffect, useState } from 'react';
const Pagination = ({ sendComponentData, dataLength }) => {
    let [currentPage, setCurrentPage] = useState(1), //目前頁面
        [showPageData, setShowPageData] = useState(10),//設定要顯示幾筆
        [pageArr, setPageArr] = useState([])
    const handleEvent = {
        clickPage: function (e, type) {
            e.preventDefault()
            if (type == 'add') {
                if (currentPage == Math.ceil(dataLength / 10)) {
                    return
                } else {
                    setCurrentPage(currentPage + 1)
                    let maxPage = showPageData * (currentPage + 1)
                    sendComponentData('min', maxPage - 9)
                    sendComponentData('max', maxPage)
                }
            } else if (type == 'decrease') {
                if (currentPage == 1) {
                    return
                } else {
                    setCurrentPage(currentPage - 1)
                    let maxPage = showPageData * (currentPage - 1)
                    sendComponentData('min', maxPage - 9)
                    sendComponentData('max', maxPage)
                }
            }
        },
        targetPage(e) {
            let thisPage = e.target.value.replace(/[^\d]/g, '');
            setCurrentPage(Number(thisPage))
            let maxPage = showPageData * thisPage
            sendComponentData('min', maxPage - 9)
            sendComponentData('max', maxPage)
        }
    }

    useEffect(() => {
        let ary = []
        for (let i = 1; i <= Math.ceil(dataLength / 10); i++) {
            ary.push(i)
        }
        setPageArr(ary)
    }, [])

    useEffect(() => { }, [currentPage, showPageData, pageArr])
    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <a href='#' onClick={e => handleEvent.clickPage(e, 'decrease')}><img src={require('../assets/images/arrow.svg').default} width='30px' style={currentPage == 1 ? { transform: 'rotate(180deg)', opacity: .3 } : { transform: 'rotate(180deg)' }} /></a>
                <div className='d-flex align-items-center justify-content-center text-light my-3 w-75'>
                    <span>第</span>
                    <select class="form-select mx-3 text-center w-50" onChange={e => handleEvent.targetPage(e)} >
                        {pageArr.map((item, index) => {
                            return (<option value={index + 1} selected={item == currentPage && 'selected'}>{index + 1}</option>)
                        })}
                    </select>
                    {/* <input type='text' className='form-control w-25 mx-3 text-center' value={currentPage} onChange={e => handleEvent.targetPage(e)} /> */}
                    <span>頁</span>
                    {/* <span className='mx-3'>/ 共 <span className='mx-2'>{Math.ceil(dataLength / 10)}</span> 頁</span> */}
                </div>
                <a href='#' onClick={e => handleEvent.clickPage(e, 'add')} ><img src={require('../assets/images/arrow.svg').default} width='30px' style={currentPage == Math.ceil(dataLength / 10) ? { opacity: .3 } : {}} /></a>
            </div>
        </>
    )
}
export default Pagination