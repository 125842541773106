import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import Title from '../../components/Title'
import Card from '../../components/Card'
import { toCurrency } from '../../assets/function/toolFunction'
import Pagination from '../../components/Pagination';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Loading from '../../components/Loading';
import AlertMes from '../../components/Alert';
import { Decrypt } from '../../assets/function/AESTool';
import moment from 'moment';
import axios from 'axios';
import { getLevelName } from '../../assets/function/getLevelName';
import Schedule from '../../components/Schedule';
import { getTypeName } from '../../assets/function/getTypeName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCoins } from '@fortawesome/free-solid-svg-icons';
import MyPoints from '../../components/MyPoints';
const MyRecord = ({ userData }) => { //我的成果
    //@ components
    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [dataMin, setDataMin] = useState(1),
        [dataMax, setDataMax] = useState(10),
        [isReloadAPI, setIsReloadAPI] = useState(false)
    //@ basic
    let [thisOption, setThisOption] = useState('本月進度'),
        [percent, setPercent] = useState(0)
    //@ 里程紀錄
    let [recordData, setRecordData] = useState([]), //里程紀錄
        [monthArr, setMonthArr] = useState([]), //選擇月份 array
        [chooseMonth, setChooseMonth] = useState(moment().format('M')),
        [myName, setMyName] = useState(''),
        [myPoints, setMyPoints] = useState(0)

    useEffect(() => {
        if (userData !== null) {
            setMyName(userData.m_username)
            handelEvent.getMonthArr()
        }
    }, [userData])

    useEffect(() => { }, [myName])

    let active = {
        borderBottom: '1.5px solid',
        paddingBottom: '10px'
    }

    const handelAPI = {
        APIPersonalRecord: function (data) { //取得個人所有紀錄
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/personal_record/${data.m_id}/${chooseMonth}`
            axios.get(API)
                .then((res) => {
                    if (res.data.data !== 0) {
                        setRecordData(res.data.data)
                    } else {
                        setRecordData([])
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(true)
                        }
                    })
                })
        },
        APIUpdateRecord: function (id) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/update_records/${id}`
            axios.delete(API)
                .then((res) => { setIsReloadAPI(true) })
                .catch((err) => {
                    console.log(err)
                    setIsReloadAPI(true)
                })
        }
    }

    const handelEvent = {
        delRecord: function (e, id, item) {
            e.preventDefault()
            Swal.fire({
                html:
                    `<div class='my-3'>
                    <h3 class="text-start text-primary fs-2 mb-3">您確定要刪除這筆紀錄？</h3>
                    <div class="text-start lh-lg">
                    <p>日期：${item.c_date}</p>
                    <p>類型：${getTypeName(item.c_type).name}</p>
                    <p>里程：${toCurrency(item.c_record)} ${getTypeName(item.c_type).unit}</p>
                    </div>
                    </div>`,
                showCancelButton: true,
                cancelButtonColor: "#00385D",
                confirmButtonColor: "#00385D",
                cancelButtonText: '再想想',
                confirmButtonText: '確定刪除',
            }).then((result) => {
                if (result.isConfirmed) {
                    handelAPI.APIUpdateRecord(id)
                    handelAPI.APIPersonalRecord(userData)
                }
            })
        },
        getMonthArr: function () {
            let firstSet = { year: 2023, month: 1 } //初始值
            let basicVal = Number(moment().format('YYYY')) > firstSet.year ? 1 : firstSet.month
            let arr = []
            for (let i = basicVal; i <= 12; i++) {
                arr.push(i)
            }
            setMonthArr(arr)
        },
        getSeason: function (month) {
            let thisMonth = Number(moment().format('M')),
                thisSeason = null, //這一季
                chooseSeason = null

            let seasonGroup = [[5, 6, 7], [8, 9, 10], [11, 12, 1], [2, 3, 4]] //* 季度月份設定

            seasonGroup.map((item, index, ary) => {
                if (item.includes(Number(thisMonth))) {
                    thisSeason = index + 1 //取得為第幾季
                }
                if (item.includes(Number(month))) {
                    chooseSeason = index + 1
                }
            })

            return thisSeason == chooseSeason ? true : false
        },
    }

    const handleComponentData = (mode, val) => {
        if (mode == 'min') {
            setDataMin(val)
        } else if (mode == 'max') {
            setDataMax(val)
        } else if (mode == 'isReload') {
            setIsReloadAPI(val)
        }
    }

    useEffect(() => {
        if (alertMes.show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 4500)
        }
    }, [alertMes])
    useEffect(() => { }, [userData, recordData, monthArr, myPoints, isReloadAPI])
    useEffect(() => {
        userData !== null && handelAPI.APIPersonalRecord(userData)
    }, [thisOption, userData, chooseMonth])

    return (
        <>
            <Loading isLoading={isLoading} />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} />
            <Header />
            <div className="container">
                <div className="col-11 col-md-8 col-lg-6 mx-auto">
                    <div className="row">
                        <div className="col-12">
                            <Title title={`${myName}成果`}>
                            </Title>
                            <div className="col-12">
                                {userData !== null && <MyPoints userData={userData} isReloadAPI={isReloadAPI} sendComponent={handleComponentData} />}
                            </div>
                            <div className='my-3'>
                                <p className='text-light mb-2'>我的 {chooseMonth} 月紀錄：</p>
                                <div className="col-12">
                                    <select class="form-select" onChange={e => setChooseMonth(e.target.value)}>
                                        {monthArr.map((item, index) => {
                                            return (
                                                <option value={item} selected={item == chooseMonth && 'selected'}>{item} 月</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mt-3'>
                        <div className='d-flex' onClick={e => (e.preventDefault(), setThisOption(e.target.innerText))}>
                            <a href='#' className='text-light w-50 text-center' style={thisOption == '本月進度' ? active : {}}>本月進度</a>
                            <a href='#' className='text-light w-50 text-center' style={thisOption == '里程紀錄' ? active : {}}>里程紀錄</a>
                        </div>
                        {
                            userData !== null && thisOption == '本月進度' &&
                            <Card>
                                <div className="container">
                                    <div className='mt-2'>
                                        <Schedule userData={userData} categoryItemShow={true} month={chooseMonth} setPercent={setPercent} />
                                    </div>
                                </div>
                            </Card>
                        }
                        {
                            userData !== null && thisOption == '里程紀錄' &&
                            <>
                                <div className="table-responsive-xxl text-center mt-3">
                                    <table className="table table-striped table-light align-middle">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>日期</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>類型</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>里程</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>加碼</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>刪除</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                recordData.sort((a, b) => { //排序建立 id 大至小(新至舊)
                                                    return b.c_id - a.c_id
                                                }).map((item, index) => {
                                                    if (index + 1 >= dataMin && index + 1 <= dataMax) {
                                                        return (
                                                            <tr>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{item.c_date}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{getTypeName(item.c_type).name}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.c_record)} {getTypeName(item.c_type).unit}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{item.c_bones == '1' && <FontAwesomeIcon icon={faCircleCheck} />}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                                    <button className='btn border-0' onClick={e => handelEvent.delRecord(e, item.c_id, item)} disabled={handelEvent.getSeason(item.c_month) == true ? '' : 'disabled'}><img src={require('../../assets/images/del.svg').default} width={18} className='del-icon'
                                                                        style={handelEvent.getSeason(item.c_month) ? {} : { opacity: 0.5 }}
                                                                    /></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {recordData.length == 0 && <p className='text-light text-center my-3'>尚無紀錄</p>}
                                </div>
                                {recordData.length !== 0 && <Pagination sendComponentData={handleComponentData} dataLength={recordData.length} />}
                            </>
                        }
                        <div className='text-center mt-4'>
                            <button type='button' className='btn btn-light text-primary' onClick={e => window.location.href = '/home'}>返回</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default MyRecord