import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import Title from '../../components/Title'
import Card from '../../components/Card'
//@日期選擇器
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';
import moment from 'moment';
//@ 表單驗證
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Decrypt } from '../../assets/function/AESTool';
import Swal from 'sweetalert2';
import AlertMes from '../../components/Alert';
import Loading from '../../components/Loading';
const RegisterMileage = ({ userData }) => { //登記里程
    registerLocale('zhTW', zhTW)
    //* value
    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })
    let [eventDate, setEventDate] = useState(null),
        [chooseType, setChooseType] = useState('0'),
        [thisBones, setThisBones] = useState('0') //0 - 無、1 - 有

    useEffect(() => { }, [userData])
    //* 表單驗證
    const validationSchema = Yup.object().shape({
        h_record_type: Yup.string()
            .required('必填'),
        h_records: Yup.number()
            .typeError('請填寫正確的數字')
            .positive('必須大於 0')
            .integer('請填寫正整數')
            .required('必填'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const Submit = (data) => {
        if (eventDate == null || eventDate == '') {
            setEventDate('')
            return
        }
        let params = new URLSearchParams();
        params.append('m_id', userData.m_id);
        params.append('c_type', data.h_record_type);
        params.append('c_record', data.h_records);
        params.append('c_month', moment(eventDate).format('M'));
        params.append('c_date', moment(eventDate).format('MM/DD'));
        params.append('c_bones', thisBones);
        handleAPI.APIProvideRecords(params)
    }

    const handleAPI = {
        APIProvideRecords: function (data) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/provide_records`
            axios.post(API, data)
                .then((res) => {
                    setIsLoading(false)
                    let { code } = res.data
                    if (code == '-1') {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">登記錯誤</h3>
                                <div class="text-start">
                                <p>請重新登記(-1)</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                reset()
                            }
                        })
                    } else {
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                    <h3 class="text-start text-primary fs-2 mb-3">登記成功~~</h3>
                                    <div class="text-start">
                                    <p>可至個人成果查看</p>
                                </div>
                                    </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setIsLoading(true)
                                window.location.href = '/home'
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">連線錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/home'
                        }
                    })
                })
        }
    }

    const handelEvent = {
        getSeason: function (month) { //取得該季首月份
            let seasonGroup = [[5, 6, 7], [8, 9, 10], [11, 12, 1], [2, 3, 4]] //* 季度月份設定

            let thisSeasonGroup = []
            seasonGroup.map((item, index, ary) => {
                if (item.includes(Number(month))) {
                    item.map((kitem) => {
                        thisSeasonGroup.push(kitem)
                    })
                }
            })
            //日期轉換時，月份需 -1 才會抓到正確日期
            return thisSeasonGroup[0] - 1
        }
    }

    useEffect(() => {
        if (alertMes.show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 4500)
        }
    }, [alertMes])
    useEffect(() => { }, [chooseType, userData, thisBones])

    return (
        <>
            <Header />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} />
            <Loading isLoading={isLoading} />
            <div className="container">
                <div className="col-11 col-md-8 col-lg-6 mx-auto">
                    <Title title='登記里程' text='快來填寫您今日完成的里程數吧！' />
                    <form onSubmit={handleSubmit(Submit)}>
                        <Card>
                            <div className='container'>
                                <div className='mb-4'>
                                    <label for='user' className='form-label'>挑戰項目</label>
                                    <select className={`form-select ${errors.h_record_type ? 'is-invalid' : ''}`}  {...register('h_record_type')} onChange={e => (setChooseType(e.target.value), setThisBones('0'))}>
                                        <option value="" selected disabled>請選擇挑戰項目--</option>
                                        <option value="2">日跑量（km）</option>
                                        <option value="1">日騎量（km）</option>
                                        <option value="3">日爬升（m）</option>
                                        <option value="4">日游量（m）</option>
                                    </select>
                                    <div className='invalid-feedback'>{errors.h_record_type?.message}</div>
                                </div>
                                <div className='mb-4'>
                                    <label for='date' className='form-label'>活動日期</label>
                                    <DatePicker locale='zhTW'
                                        className={`form-control ${eventDate == '' && 'is-invalid'}`}
                                        dateFormat='MM/dd'
                                        selected={eventDate}
                                        onChange={(date) => (setEventDate(date))}
                                        minDate={
                                            new Date().getTime() <= new Date('2023-08-10 22:00:00').getTime() ?
                                                new Date(moment().format('YYYY'), 4, 1)
                                                :
                                                new Date(moment().format('YYYY'), handelEvent.getSeason(moment().format('M')), 1)
                                        }
                                        maxDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        // showYearDropdown
                                        dropdownMode='select'
                                        placeholderText='月/日'>
                                    </DatePicker>
                                    {eventDate == '' &&
                                        <div className='text-danger small mt-1'>必填</div>
                                    }
                                </div>
                                {
                                    (chooseType == '1' || chooseType == '3') &&
                                    <div className='mb-4'>
                                        <p>是否有加碼</p>
                                        <div className='d-flex'>
                                            <div class="form-check mx-2">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={thisBones == '1' && 'checked'} onChange={e => setThisBones('1')} />
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    是
                                                </label>
                                            </div>
                                            <div class="form-check mx-2">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={thisBones == '0' && 'checked'} onChange={e => setThisBones('0')} />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    否
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div>
                                    <p>里程數</p>
                                    <div className='input-group mb-3'>
                                        <input type='text' className={`form-control ${errors.h_records ? 'is-invalid' : ''}`} placeholder='' {...register('h_records')} />
                                        <span className='input-group-text fw-bolder' id='basic-addon2'>{chooseType == '4' || chooseType == '3' ? 'm' : 'km'}</span>
                                        <div className='invalid-feedback'>{errors.h_records?.message}</div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div className='row mt-5'>
                            <div className='col-6'><button type='button' className='btn btn-light text-primary w-100' onClick={e => window.location.href = '/home'}>返回</button></div>
                            <div className='col-6'><button type='submit' className='btn btn-secondary w-100'>登記</button></div>
                        </div>
                    </form>
                </div>
            </div >
        </>
    )
}
export default RegisterMileage