import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import MyPoints from '../../components/MyPoints';
import Title from '../../components/Title';
const RedemptionPoints = ({ userData }) => {
    let [isReloadAPI, setIsReloadAPI] = useState(false)

    const handleComponentData = (mode, val) => {
        if (mode == 'isReload') {
            setIsReloadAPI(val)
        }
    }
    return (
        <>
            <Header />
            <div className="container">
                <div className="col-11 col-md-8 col-lg-6 mx-auto">
                    <div className='text-center'>
                        <p className='text-light fw-bolder text-center fs-5'>*點數關閉兌換*</p>
                        <a href='/home' className='btn btn-secondary mt-3'>回首頁</a>
                    </div>
                    {/* {userData !== null &&
                        <>
                            <Title title={`兌換點數`} />
                            <MyPoints userData={userData} isReloadAPI={isReloadAPI} sendComponent={handleComponentData} isProvide={true} />
                        </>
                    } */}
                </div>
            </div>
        </>
    )
}
export default RedemptionPoints