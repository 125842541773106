import React, { Children, useState } from 'react';
const Title = ({ title, text, children }) => {
    return (
        <>
            <div className='text-light pb-2'>
                <h3 className='mb-3'>{title}</h3>
                <span>{text}</span>
                {children}
            </div>
        </>
    )
}
export default Title