import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Loading from './Loading';
import Swal from 'sweetalert2';
import moment from 'moment';
import Card from './Card';
import Pagination from './Pagination';
//@ 表單驗證
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const MyPoint = ({ userData, isReloadAPI, isProvide, sendComponent }) => {
    let seasonGroup = [[5, 6, 7], [8, 9, 10], [11, 12, 1], [2, 3, 4]] //* 季度月份設定

    let [lastSeasonCoin, setLastSeasonCoin] = useState(0), //上季
        [thisSeasonCoin, setThisSeasonCoin] = useState(0), //本季點數
        [thisMonthCoin, setThisMonthCoin] = useState(0), //本月點數
        [recordData, setRecordData] = useState([]),
        [isLoading, setIsLoading] = useState(true),
        [dataMin, setDataMin] = useState(1),
        [dataMax, setDataMax] = useState(10)

    //@ 點數 data
    let [seasonPointRecord, setSeasonPointRecord] = useState([]), //當季兌換紀錄
        [lastProvidePointSum, setLastProvidePointSum] = useState(0),
        [thisProvidePointSum, setThisProvidePointSum] = useState(0),
        [isShowPointBox, setIsShowPointBox] = useState(false)

    //* 表單驗證
    const validationSchema = Yup.object().shape({
        c_redemption: Yup.number()
            .typeError('請填寫正確的數字')
            .positive('必須大於 0')
            .integer('請填寫正整數')
            .required('必填'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const Submit = (data) => {
        let resetCoinDate = {
            s1: { start: '09/01', end: '04/30', endYear: moment().add(1, 'Y').format('YYYY') },
            s2: { start: '12/01', end: '07/31', endYear: moment().add(1, 'Y').format('YYYY') },
            s3: { start: '03/01', end: '10/31', endYear: moment().format('YYYY') },
            s4: { start: '06/01', end: '01/31', endYear: moment().add(1, 'Y').format('YYYY') }
        }

        let thisTime = moment().valueOf(),
            startTime = moment(`${moment().format('YYYY')}/${resetCoinDate[`s${handelEvent.getSeason().last}`].start}`).valueOf(),
            endTime = moment(`${resetCoinDate[`s${handelEvent.getSeason().last}`].endYear}/${resetCoinDate[`s${handelEvent.getSeason().last}`].end}`).valueOf()

        let postData = {
            m_id: userData.m_id,
            c_month: null,
            c_redemption: data.c_redemption,
            c_date: moment().format('MM/DD')
        }

        if (thisTime >= startTime && thisTime <= endTime) {
            //判斷是否本季有點數可以扣
            if (((Number(thisSeasonCoin) - Number(thisProvidePointSum)) - Number(data.c_redemption)) >= 0) {
                postData.c_month = handelEvent.getSeason().this
            } else { //不足
                Swal.fire({
                    html:
                        `<div class='my-3'>
                        <h3 class="text-center text-primary fs-2 mb-3">點數兌換不足</h3>
                        </div>`,
                    confirmButtonColor: "#00385D",
                    confirmButtonText: '關閉',
                }).then((result) => {
                    if (result.isConfirmed) {
                        reset()
                    }
                })
                return
            }
        } else {
            if ((Number(lastSeasonCoin) - Number(lastProvidePointSum)) > 0) {
                if (((Number(lastSeasonCoin) - Number(lastProvidePointSum)) - Number(data.c_redemption)) >= 0) {
                    postData.c_month = handelEvent.getSeason().last
                } else {
                    postData.c_month = handelEvent.getSeason().last
                    postData.c_redemption = Number(lastSeasonCoin) - Number(lastProvidePointSum)
                }
            } else {
                if (((Number(thisSeasonCoin) - Number(thisProvidePointSum)) - Number(data.c_redemption)) >= 0) {
                    postData.c_month = handelEvent.getSeason().this
                } else { //不足
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                            <h3 class="text-center text-primary fs-2 mb-3">點數兌換不足</h3>
                            </div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            reset()
                        }
                    })
                    return
                }
            }
        }

        if (postData.c_month !== null) {
            handleAPI.APIProvideRedemption(postData)
        } else {
            Swal.fire({
                html:
                    `<div class='my-3'>
                    <h3 class="text-start text-primary fs-2 mb-3">兌換錯誤</h3>
                    <div class="text-start">
                    <p>請重新兌換</p>
                </div>
                    </div>`,
                confirmButtonColor: "#00385D",
                confirmButtonText: '關閉',
            }).then((result) => {
                if (result.isConfirmed) {
                    reset()
                }
            })
        }
    }

    const handleAPI = {
        APIAllPersonalRecord: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/all_personal_record/${userData.m_id}`
            axios.get(API)
                .then((res) => {
                    if (res.data.data !== 0) {
                        setRecordData(res.data.data)
                    } else {
                        setRecordData([])
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                            <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                            <div class="text-start">
                            <p>請洽詢管理人員</p>
                        </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(true)
                        }
                    })
                })
        },
        APIGetPersonalRedemption: function (lastSeason, thisSeason) {
            let APIs = [
                `${process.env.REACT_APP_API}/personal_redemption/${userData.m_id}/${lastSeason}`, //上季
                `${process.env.REACT_APP_API}/personal_redemption/${userData.m_id}/${thisSeason}`, //本季
                `${process.env.REACT_APP_API}/personal_redemption_detail_nomonth/${userData.m_id}`
            ]
            axios.all(APIs.map((item) => axios.get(item)))
                .then((res) => {
                    setIsShowPointBox(true)
                    let lastProvidePointData = res[0].data.data[0]['SUM(c_redemption)'], //上季
                        thisProvidePointData = res[1].data.data[0]['SUM(c_redemption)'], //本季
                        personalRedemptionDetail = res[2].data //當季總兌換紀錄

                    setLastProvidePointSum(lastProvidePointData)
                    setThisProvidePointSum(thisProvidePointData)

                    if (personalRedemptionDetail.data !== 0) {
                        setSeasonPointRecord(personalRedemptionDetail.data)
                    }
                })
                .catch((err) => {
                    setIsShowPointBox(true)
                    console.log(err)
                })
        },
        APIProvideRedemption: function (data) {
            setIsLoading(true)
            let params = new URLSearchParams();
            params.append('m_id', data.m_id);
            params.append('c_month', data.c_month);
            params.append('c_redemption', data.c_redemption);
            params.append('c_date', data.c_date);
            let API = `${process.env.REACT_APP_API}/provide_redemption`
            axios.post(API, params)
                .then((res) => {
                    setIsLoading(false)
                    let { code } = res.data
                    if (code == '-1') {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">兌換錯誤</h3>
                                <div class="text-start">
                                <p>請重新兌換(-1)</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                reset()
                            }
                        })
                    } else {
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                    <h3 class="text-center text-primary fs-2 mb-3">兌換成功~~</h3>
                                    </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setIsLoading(false)
                                handleAPI.APIGetPersonalRedemption(handelEvent.getSeason().last, handelEvent.getSeason().this)
                                reset()
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                            <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                            <div class="text-start">
                            <p>請洽詢管理人員</p>
                        </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(false)
                            reset()
                        }
                    })
                })

        }
    }

    const handelEvent = {
        getSeason: function () {
            let thisMonth = Number(moment().format('M')),
                thisSeason = null, //這一季
                lastSeason = null //上一季
            seasonGroup.map((item, index, ary) => {
                if (item.includes(Number(thisMonth))) {
                    thisSeason = index + 1 //取得為第幾季
                    lastSeason = index == 0 ? ary.length : index //取得上一季
                }
            })

            return ({ this: thisSeason, last: lastSeason, month: thisMonth })
        },
        seasonToMonthText: function (season) {
            let text = ''
            seasonGroup.map((item, index) => {
                if (season == index + 1) {
                    text = `${item[0]}~${item[2]}月`
                }
            })
            return text
        },
        calCoin: function (data) {
            let coinData = { //類型點數
                1: { goal: 1, coin: 1 }, //單車
                2: { goal: 1, coin: 3 }, //跑步
                3: { goal: 25, coin: 1 }, //爬升
                4: { goal: 50, coin: 1 } //游泳
            }

            //@-- 1.紀錄分組 設定 --@//
            let isSeasonData = { s1: [], s2: [], s3: [], s4: [] } //* 各分季 data
            seasonGroup.map((item, index, ary) => {
                data.map((kitem) => {
                    if (item.includes(Number(kitem.c_month))) {
                        isSeasonData[`s${index + 1}`].push(kitem)
                    }
                })
            })
            //@-- 1.紀錄分組 設定 --@//

            //@-- 2.點數計算 --@//
            function getSeasonCoin(type, num) { //type: month、season
                let coinSum = 0
                const coinJudge = (item) => {
                    if (item.C_bones == '1') { //有加碼
                        coinSum += Math.round(((Number(item.c_record) + Number(item.c_record * 0.2)) / coinData[item.c_type].goal) * coinData[item.c_type].coin)
                    } else {
                        coinSum += Math.round((Number(item.c_record) / coinData[item.c_type].goal) * coinData[item.c_type].coin)
                    }
                }

                if (type == 'month') {
                    data.filter((val) => {
                        if (val.c_month == num) {
                            return val
                        }
                    }).map((item) => { coinJudge(item) })
                } else if (type == 'season') {
                    isSeasonData[`s${num}`].map((item) => { coinJudge(item) })
                }
                return Math.round(coinSum)
            }

            //*--- 歸 0 計算 ---*//
            /*  
                s1：5,6,7 => 8月用完 (9/1 歸 0) 9/1 ~ 4/30
                s2：8,9,10 => 11 月 (12/1 歸 0) 12/1 ~ 7/31
                s3：11,12,1 => 2 月 (3/1 歸 0) 3/1 ~ 10/31
                s4：2,3,4 => 5 月 (6/1 歸 0) 6/1 ~ 1/31 
            */

            let resetCoinDate = {
                s1: { start: '09/01', end: '04/30', endYear: moment().add(1, 'Y').format('YYYY') },
                s2: { start: '12/01', end: '07/31', endYear: moment().add(1, 'Y').format('YYYY') },
                s3: { start: '03/01', end: '10/31', endYear: moment().format('YYYY') },
                s4: { start: '06/01', end: '01/31', endYear: moment().add(1, 'Y').format('YYYY') }
            }

            let thisTime = moment().valueOf(),
                startTime = moment(`${moment().format('YYYY')}/${resetCoinDate[`s${handelEvent.getSeason().last}`].start}`).valueOf(),
                endTime = moment(`${resetCoinDate[`s${handelEvent.getSeason().last}`].endYear}/${resetCoinDate[`s${handelEvent.getSeason().last}`].end}`).valueOf()

            if (thisTime >= startTime && thisTime <= endTime) { //在符合時間區間內，歸0
                // setLastSeasonCoin(0)
                setLastSeasonCoin(getSeasonCoin('season', handelEvent.getSeason().last))
            } else {
                setLastSeasonCoin(getSeasonCoin('season', handelEvent.getSeason().last))
            }
            //*--- 歸 0 計算 ---*//

            setThisSeasonCoin(getSeasonCoin('season', handelEvent.getSeason().this))
            setThisMonthCoin(getSeasonCoin('month', handelEvent.getSeason().month))

            //@-- 2.點數計算 --@//
        }
    }

    const handleComponentData = (mode, val) => {
        if (mode == 'min') {
            setDataMin(val)
        } else if (mode == 'max') {
            setDataMax(val)
        }
    }

    useEffect(() => {
        handleAPI.APIAllPersonalRecord()
        handleAPI.APIGetPersonalRedemption(handelEvent.getSeason().last, handelEvent.getSeason().this)
    }, [])
    useEffect(() => {
        if (isReloadAPI) {
            handleAPI.APIAllPersonalRecord()
            handleAPI.APIGetPersonalRedemption(handelEvent.getSeason().last, handelEvent.getSeason().this)
            sendComponent('isReload', false)
        }
    }, [isReloadAPI])
    useEffect(() => {
        handelEvent.calCoin(recordData)
    }, [recordData])
    useEffect(() => { }, [lastSeasonCoin, thisSeasonCoin, thisMonthCoin, lastProvidePointSum, thisProvidePointSum, isShowPointBox])

    let active = {
        borderBottom: '1.5px solid',
        paddingBottom: '10px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='bg-primary-light rounded p-3 h-100'>
                <p className='text-primary mb-2'>
                    <FontAwesomeIcon icon={faCoins} />
                    <span className='ms-2'>高美點數</span>
                </p>
                <div className='row'>
                    {isShowPointBox &&
                        <>
                            <div className="col-4 text-center">
                                <p className='small'>上期結餘</p>
                                <p className='text-primary fs-3'>{Number(lastSeasonCoin) - Number(lastProvidePointSum)} <span style={{ fontSize: '14px' }}>點</span></p>
                            </div>
                            <div className="col-4 text-center">
                                <p className='small'>本期點數</p>
                                <p className='text-primary fs-3'>{Number(thisSeasonCoin) - Number(thisProvidePointSum)} <span style={{ fontSize: '14px' }}>點</span></p>
                            </div>
                            <div className="col-4 text-center">
                                <p className='small'>本月新增</p>
                                <p className='text-primary fs-3'>{thisMonthCoin} <span style={{ fontSize: '14px' }}>點</span></p>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                isProvide &&
                <>
                    <div>
                        <form onSubmit={handleSubmit(Submit)}>
                            <Card>
                                <div className='container'>
                                    <div>
                                        <p>我要兌換點數</p>
                                        <div className='input-group mb-3'>
                                            <input type='tel' className={`form-control ${errors.c_redemption ? 'is-invalid' : ''}`} placeholder='' {...register('c_redemption')} />
                                            <span className='input-group-text fw-bolder' id='basic-addon2'>點</span>
                                            <div className='invalid-feedback'>{errors.c_redemption?.message}</div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <button type='submit' className='btn btn-secondary'>兌換</button>
                                    </div>
                                </div>
                            </Card>
                        </form>
                    </div>
                    <div className='mt-3'>
                        <div className='d-flex justify-content-center'>
                            <p className='text-light w-50 text-center' style={active}>兌換紀錄</p>
                        </div>
                        {
                            userData !== null &&
                            <>
                                <div className="table-responsive-xxl text-center mt-3">
                                    <table className="table table-striped table-light align-middle">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>季度</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>日期</th>
                                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>點數</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                seasonPointRecord.sort((a, b) => { return b.r_id - a.r_id }).map((item, index) => {
                                                    if (index + 1 >= dataMin && index + 1 <= dataMax) {
                                                        return (
                                                            <tr>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{item.c_month == handelEvent.getSeason().this ? `本期(${handelEvent.seasonToMonthText(item.c_month)})` : `上期(${handelEvent.seasonToMonthText(item.c_month)})`}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{item.c_date}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{item.c_redemption}點</td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {seasonPointRecord.length == 0 && <p className='text-light text-center my-3'>尚無紀錄</p>}
                                </div>
                                {seasonPointRecord.length !== 0 && <Pagination sendComponentData={handleComponentData} dataLength={seasonPointRecord.length} />}
                            </>
                        }
                        <div className='text-center mt-4'>
                            <button type='button' className='btn btn-light text-primary' onClick={e => window.location.href = '/home'}>返回</button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default MyPoint