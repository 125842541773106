import React, { useState } from 'react';
const RouterWrapper = ({ children }) => {
    return (
        <>
            <div className='bg-primary' style={{ minHeight: '100vh' }}>
                <div style={{ minHeight: '87vh' }}>{children}</div>
                <footer className='text-center py-4'>
                    <img src={require('../../assets/images/golden-logo.svg').default} width='200px' />
                </footer>
            </div>
        </>
    )
}
export default RouterWrapper