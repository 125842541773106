import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import axios from 'axios';
import Loading from '../components/Loading';
import AlertMes from '../components/Alert';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'
import { Decrypt, Encrypt } from '../assets/function/AESTool';
import { getLevelName } from '../assets/function/getLevelName';
import Schedule from '../components/Schedule';
import { getTypeName } from '../assets/function/getTypeName';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCoins, faSpellCheck } from '@fortawesome/free-solid-svg-icons';
import MyPoints from '../components/MyPoints';
import Pagination from '../components/Pagination';
const HomePage = ({ userData }) => {
    const history = useHistory()
    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [dataMin, setDataMin] = useState(1),
        [dataMax, setDataMax] = useState(10)

    let [percent, setPercent] = useState(0),
        [totalPlayer, setTotalPlayer] = useState(0),
        [latestTenData, setLatestTenDat] = useState([])

    useEffect(() => {
        if (userData !== null) {
            handleAPI.checkUser(userData.mobileNum)
        }
    }, [userData])

    const handleAPI = {
        checkUser: function (mobileNum) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/check_user/${mobileNum}`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        handleAPI.APITotalPlayer()
                    } else if (code == '-1') {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">您非健康存摺會員(-1)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        })
                    } else {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(${code})</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">連線錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    })
                })
        },
        APITotalPlayer: function () {
            let API = `${process.env.REACT_APP_API}/total_player`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == 0) {
                        setTotalPlayer(Number(data[0].total))
                        handleAPI.APILatestTen() //取得最近 10 筆記錄
                    } else {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(${code})</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div></div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setIsLoading(true)
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(true)
                        }
                    })
                })
        },
        APILatestTen: function () {
            let API = `${process.env.REACT_APP_API}/all_list`
            axios.get(API)
                .then((res) => {
                    let { data } = res.data
                    setIsLoading(false)
                    setLatestTenDat(data.reverse())
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleEvent = {
        logout: function () {
            setIsLoading(true)
            Cookies.remove('_userKey')
            setAlertMes({ mes: '正在登出', show: true, color: 'secondary' })
            setTimeout(() => {
                window.location.href = '/'
            }, 1000)
        }
    }

    const handleComponentData = (mode, val) => {
        if (mode == 'min') {
            setDataMin(val)
        } else if (mode == 'max') {
            setDataMax(val)
        }
    }

    useEffect(() => {
        if (alertMes.show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 4500)
        }
    }, [alertMes])
    useEffect(() => { }, [totalPlayer, latestTenData, percent])
    return (
        <>
            <Loading isLoading={isLoading} />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} />
            <Header />
            <div className="container">
                <div className="col-11 col-md-8 col-lg-6 mx-auto">
                    <div className='text-light'>
                        <p className='mb-3'>總參賽人數：<span className='text-secondary'>{totalPlayer}</span></p>
                    </div>
                    {userData !== null &&
                        <>
                            <Schedule categoryItemShow={false} userData={userData} month={moment().format('M')} setPercent={setPercent} newRecord={true} />
                            <div className='mb-4'><MyPoints userData={userData} isReloadAPI={false} sendComponent={handleComponentData} /></div>
                        </>
                    }
                    <div className='row justify-content-lg-center button-group mb-2'>
                        <div className="col-6 col-lg-3" style={{ marginBottom: '24px' }}>
                            <button className='btn btn-secondary w-100' onClick={e => window.location.href = '/registerMileage'}>
                                <img src={require('../assets/images/edit.svg').default} width='32px' />
                                <span>登記里程</span>
                            </button>
                        </div>
                        <div className="col-6 col-lg-3" style={{ marginBottom: '24px' }}>
                            <button className='btn btn-secondary w-100' onClick={e => window.location.href = '/myRecord'}>
                                <img src={require('../assets/images/medal.svg').default} width='24px' />
                                <span>個人成果</span>
                            </button>
                        </div>
                        {/* <div className="col-6 col-lg-3">
                            <button className='btn btn-secondary w-100' onClick={e => window.location.href = '/redemptionPoints'}>
                                <img src={require('../assets/images/coins.svg').default} width='27px' />
                                <span>兌換點數</span>
                            </button>
                        </div> */}
                        {/* <div className="col-6 col-lg-3">
                            <button className='btn btn-secondary w-100' onClick={e => window.location.href = '/chooseGroup'}>
                                <img src={require('../assets/images/user.svg').default} width='32px' />
                                <span>組別資料</span>
                            </button>
                        </div> */}
                        <div className="col-6 col-lg-3">
                            <button className='btn btn-secondary w-100' onClick={handleEvent.logout}>
                                <img src={require('../assets/images/logout.svg').default} width='27px' />
                                <span>登出</span>
                            </button>
                        </div>
                    </div>
                    <div className='pt-4'>
                        <p className='text-light'>所有人登記紀錄：</p>
                        <div className="table-responsive-xxl text-center mt-3">
                            <table className="table table-striped table-light align-middle">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>姓名</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>類型</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>里程</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>加碼</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        latestTenData.map((item, index) => {
                                            if (index + 1 >= dataMin && index + 1 <= dataMax) {
                                                return (
                                                    <tr>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.m_username}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{getTypeName(item.c_type).name}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{`${item.c_record} ${getTypeName(item.c_type).unit}`}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.c_bones == '1' && <FontAwesomeIcon icon={faCircleCheck} />}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                            {latestTenData.length == 0 && <p className='text-light text-center my-3'>尚無紀錄</p>}
                        </div>
                        {latestTenData.length !== 0 && <Pagination sendComponentData={handleComponentData} dataLength={latestTenData.length} />}
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePage