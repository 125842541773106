import React, { useState } from 'react';
import { toCurrency } from '../assets/function/toolFunction';
import Loading from './Loading';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useEffect } from 'react';
import { getTypeName } from '../assets/function/getTypeName';
const Schedule = ({ categoryItemShow, userData, month, setPercent, newRecord }) => { //進度表
    let [isLoading, setIsLoading] = useState(true)
    let [getResData, setGetResData] = useState(null), //接收 response data
        [totalRecordData, setTotalRecordData] = useState(null),
        [totalPercent, setTotalPercent] = useState(0),
        [recordData, setRecordData] = useState(null),
        [myPoints, setMyPoints] = useState(0),
        [myNewRecordData, setMyNewReocrdData] = useState(null)

    const handelAPI = {
        APITotalPersonalRecord: function (data) { //取得個人當月運動紀錄總數
            let API = `${process.env.REACT_APP_API}/total_personal_record/${userData.m_id}/${month}`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == 0) {
                        setIsLoading(false)
                        if (data !== 0) {
                            setGetResData(data)
                        } else {
                            setGetResData([])
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(true)
                        }
                    })
                })
        },
        APIPersonalRecord: function (data) { //取得個人所有紀錄
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/personal_record/${data.m_id}/${Number(moment().format('M'))}`
            axios.get(API)
                .then((res) => {
                    if (res.data.data !== 0) {
                        setRecordData(res.data.data)
                        handelEvent.myNewRecord(res.data.data)
                    } else {
                        setRecordData([])
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div></div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(true)
                        }
                    })
                })
        }
    }

    const handelEvent = {
        tidyData: function (data) { //整理 data
            let array = []
            let typeBasicData = [
                { type: '1', color: '#F8777A', icon: require(`../assets/images/bike-icon.svg`).default, name: '月騎量', unit: 'km' },
                { type: '2', color: '#E7A42F', icon: require(`../assets/images/run-icon.svg`).default, name: '月跑量', unit: 'km' },
                { type: '3', color: '#019EA3', icon: require(`../assets/images/climbing-icon.svg`).default, name: '月爬升', unit: 'm' },
                { type: '4', color: '#7DAFE0', icon: require(`../assets/images/swim-icon.svg`).default, name: '月游量', unit: 'm' }
            ]

            function toObject(arr) {
                return arr.reduce((pv, cv, i) => {
                    pv[cv.c_type] = cv;
                    return pv;
                }, {});
            }
            let obj = data !== null ? toObject(data) : []

            typeBasicData.map((item) => {
                array.push({
                    type: item.type,
                    basicData: item,
                    total: obj[item.type] == undefined ? 0 : obj[item.type].total
                })
            })
            setTotalRecordData(array)
        },
        myNewRecord: function (data) {
            let typeBasicData = {
                1: { type: '1', color: '#F8777A', icon: require(`../assets/images/bike-icon.svg`).default, name: '月騎量', unit: 'km' },
                2: { type: '2', color: '#E7A42F', icon: require(`../assets/images/run-icon.svg`).default, name: '月跑量', unit: 'km' },
                3: { type: '3', color: '#019EA3', icon: require(`../assets/images/climbing-icon.svg`).default, name: '月爬升', unit: 'm' },
                4: { type: '4', color: '#7DAFE0', icon: require(`../assets/images/swim-icon.svg`).default, name: '月游量', unit: 'm' }
            }
            data.map((item, index, ary) => {
                if (index + 1 == ary.length) {
                    setMyNewReocrdData({ typeData: typeBasicData[item.c_type], item: item })
                }
            })
        }
    }

    useEffect(() => {
        handelAPI.APITotalPersonalRecord()
        handelAPI.APIPersonalRecord(userData)
    }, [month])

    useEffect(() => {
        getResData !== null && handelEvent.tidyData(getResData)
    }, [getResData])
    useEffect(() => { }, [totalPercent, myPoints, myNewRecordData])
    return (
        <>
            <Loading isLoading={isLoading} />
            {
                newRecord &&
                <>
                    <div>
                        <div className='d-flex justify-content-between align-items-end'>
                            <p className='small text-light mb-2'>我的 {moment().format('M')} 月最新登記紀錄</p>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className='bg-light rounded mb-4 p-3 d-flex flex-column align-items-center'>
                                    {
                                        myNewRecordData !== null ?
                                            <>
                                                <div className='circle-box text-center mb-2' style={{ background: `${myNewRecordData.typeData.color}` }}>
                                                    <img src={myNewRecordData.typeData.icon} className='position-center' />
                                                </div>
                                                <div>
                                                    <p>{myNewRecordData.item.c_date}</p>
                                                    <p>{getTypeName(myNewRecordData.item.c_type).name}</p>
                                                    <p className='text-primary' style={{ fontSize: '18px' }}>{myNewRecordData.item.c_record} {getTypeName(myNewRecordData.item.c_type).unit}</p>
                                                </div>
                                            </>
                                            :
                                            <div>您尚未有里程紀錄</div>
                                    }
                                </div>
                            </div>
                            <div className="col-6 position-relative">
                                <img className='challenge-main-img position-absolute' src={require('../assets/images/run.svg').default} alt='跑步' width='50%' style={{ bottom: 0, right: '24px' }} />
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                categoryItemShow && totalRecordData !== null &&
                <div>
                    <p className='text-primary'>分類項目：</p>
                    <div className="d-flex flex-wrap justify-content-around mt-3">
                        {
                            totalRecordData.map((item, index) => {
                                return (
                                    <div className='my-2'>
                                        <div className='circle-box text-center' style={{ background: `${item.basicData.color}` }}>
                                            <img src={item.basicData.icon} className='position-center' />
                                        </div>
                                        <div className='text-center text-primary'>
                                            <p>{item.basicData.name}</p>
                                            <p className='fs-5'>{toCurrency(item.total)} {item.basicData.unit}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}
export default Schedule