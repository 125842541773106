import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import axios from 'axios';
import Loading from '../components/Loading';
//@ 表單驗證
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Encrypt, Decrypt } from '../assets/function/AESTool'
const Login = () => {
    const history = useHistory()
    let [isLoading, setIsLoading] = useState(true)

    //* 確認登入狀態
    useEffect(() => {
        let userKey = Cookies.get('_userKey')
        if (userKey !== undefined) {
            setIsLoading(true)
            setTimeout(() => { window.location.href = '/home' }, 1000)
        } else {
            setIsLoading(false)
        }
    }, [])

    //* 表單驗證
    const validationSchema = Yup.object().shape({
        Mobile: Yup.string()
            .min(10, '請輸入正確的手機')
            .max(10, '請輸入正確的手機')
            .matches(/^09[0-9]{8}$/, '請輸入正確的手機格式')
            .required('必填'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const Submit = (data) => {
        handleAPI.checkUser(data.Mobile)
    }

    const handleAPI = {
        checkUser: function (mobileNum) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_API}/check_user/${mobileNum}`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        // setIsLoading(true)
                        var now = new Date();
                        var expires = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
                        let userData = data[0]
                        userData.mobileNum = mobileNum
                        // -- data 加密 存入 cookies
                        document.cookie = `_userKey=${Encrypt(JSON.stringify(userData))} ;expires=${expires.toGMTString()}`;
                        setTimeout(() => { window.location.href = '/home' }, 1000)
                    } else if (code == '-1') {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">您非健康存摺會員(-1)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        })
                    } else {
                        setIsLoading(false)
                        Swal.fire({
                            html:
                                `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">錯誤(${code})</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                            confirmButtonColor: "#00385D",
                            confirmButtonText: '關閉',
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    Swal.fire({
                        html:
                            `<div class='my-3'>
                                <h3 class="text-start text-primary fs-2 mb-3">連線錯誤(err)</h3>
                                <div class="text-start">
                                <p>請洽詢管理人員</p>
                            </div>
                                </div>`,
                        confirmButtonColor: "#00385D",
                        confirmButtonText: '關閉',
                    })
                })
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='text-center pt-5 pb-3'>
                <img src={require('../assets/images/main.svg').default} width='220px' />
            </div>
            <div className="container">
                <form onSubmit={handleSubmit(Submit)} className='col-11 col-md-8 col-lg-4 mx-auto'>
                    <Card>
                        <div className='container'>
                            <div className='mb-4'>
                                <label for='phoneNumber' className='form-label'>手機號碼</label>
                                <input type='tel' className={`form-control ${errors.Mobile ? 'is-invalid' : ''}`} id='phoneNumber' {...register('Mobile')} />
                                <div className='invalid-feedback'>{errors.Mobile?.message}</div>
                            </div>
                            {/* <div className='mb-3 form-check d-flex justify-content-end'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck1' />
                                <label className='form-check-label ms-2' for='exampleCheck1'>記住我</label>
                            </div> */}
                        </div>
                    </Card>
                    <div className='text-center mt-3'>
                        <button type='submit' className='btn btn-secondary text-primary fw-bolder w-50'>登入</button>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Login