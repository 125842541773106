import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap'
//<AlertMes mes='文字' show={alertShow} color='alert 顏色' /> 樣板
const AlertMes = ({ show, color, mes }) => {
    let alertStyle = {
        zIndex: '10000',
        position: 'fixed',
        top: '85px',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center'
    }
    return (
        <>
            <Alert show={show} variant={color} className='col-10 col-xs-8 col-sm-6 col-md-5' style={alertStyle}>
                {mes}
            </Alert>
        </>
    )
}
export default AlertMes