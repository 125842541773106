import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useRoutes, useLocation } from 'react-router-dom';

import RouterWrapper from '../components/layout/RouterWrapper'
import Login from '../view/Login';
import HomePage from '../view/HomePage';
import RegisterMileage from '../view/inPage/RegisterMileage';
import RedemptionPoints from '../view/inPage/RedemptionPoints'
import MyRecord from '../view/inPage/MyRecord';
import Rank from '../view/inPage/Rank';
import ChooseGroup from '../view/inPage/GroupInfo/ChooseGroup';
import GroupInfo from '../view/inPage/GroupInfo/GroupInfo';
import SignUpGroup from '../view/inPage/SignupGroup';
import Loading from '../components/Loading'

import level from '../assets/data/level.json'
import Cookies from 'js-cookie';
import { Decrypt } from '../assets/function/AESTool';
import AlertMes from '../components/Alert';

const AppRouter = () => {
    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [userData, setUserData] = useState(null)
    useEffect(() => {
        let userKey = Cookies.get('_userKey')
        if (window.location.pathname !== '/') {
            if (userKey == null || userKey == undefined) {
                setIsLoading(true)
                setAlertMes({ mes: '逾時登入，請重新登入', show: true, color: 'danger' })
                setTimeout(() => { window.location.href = '/' }, 1500)
            } else {
                setUserData(JSON.parse(Decrypt(userKey)))
            }
        }
    }, [])

    useEffect(() => {
        if (userData !== null) {
            setIsLoading(false)
        }
    }, [userData])

    useEffect(() => {
        if (alertMes.show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 4500)
        }
    }, [alertMes])
    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} />
            <Loading isLoading={isLoading} />
            <Router>
                <RouterWrapper>
                    <Switch>
                        <Route exact path='/home'><HomePage userData={userData} /></Route>
                        <Route exact path='/registerMileage'><RegisterMileage userData={userData} /></Route>
                        <Route exact path='/myRecord'><MyRecord userData={userData} /></Route>
                        <Route exact path='/redemptionPoints'><RedemptionPoints userData={userData} /></Route>
                        <Route exact path='/'><Login /></Route>
                        {/* <Route exact path='/signupGroup'><SignUpGroup level={level} /></Route> */}
                        {/* <Route exact path='/rank'><Rank level={level} /></Route> 狂人排名*/}
                        {/* <Route exact path='/chooseGroup'><ChooseGroup level={level} /></Route> */}
                        {/* <Route exact path='/groupInfo'><GroupInfo /></Route> */}
                    </Switch>
                </RouterWrapper>
            </Router>
        </>
    )
}
export default AppRouter